import {MapContainer, TileLayer, useMap} from 'react-leaflet'
import {useGeolocated} from "react-geolocated";
import {useEffect, useState} from "react";
import {LeafletTrackingMarker} from "react-leaflet-tracking-marker";
import L from "leaflet";
import "leaflet-routing-machine";

import sedan from "../../assets/img/icons/leaflet/sedan.png";
import {
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Media,
    UncontrolledDropdown
} from "reactstrap";

const icon = L.icon({
    iconSize: [45, 45],
    popupAnchor: [2, -20],
    iconUrl: sedan
});

const uuid = "54261bdb";
var interval;
var socket;

const CenterOnMyPosition = ({coords}) => {
    const map = useMap();

    if (!map || !coords) {
        return null;
    }

    map.setView([coords.latitude, coords.longitude]);

    return null;

}

const DisplayPosition = ({message}) => {

    const [currentPos, setCurrentPos] = useState([0, 0]);
    const [prevPos, setPrevPos] = useState(currentPos)

    useEffect(() => {
        if (message) {
            if (prevPos[1] !== currentPos[1] && prevPos[0] !== currentPos[1]) {
                setPrevPos(currentPos)
            }
            let position = message.messagePayload.position;
            setCurrentPos([position.latitude, position.longitude]);
        }

    }, [message])

    return (
        <>
            {currentPos &&
                <LeafletTrackingMarker icon={icon} position={currentPos} previousPosition={prevPos}
                                       duration={1000}/>
            }
        </>
    )
}

// const CenterOnMyPosition = ({coords}) => {
//     const map = useMap();
//     const [markers, setMarkers] = useState([]);
//     const [currentPos, setCurrentPos] = useState([63.412911139796655, 10.372784924228824])
//     const [prevPos, setPrevPos] = useState(currentPos)
//
//     const addMarker = (marker) => {
//         let markersTemp = markers;
//         markersTemp = markersTemp.filter(m => m.key !== marker.key);
//         markersTemp.push(marker);
//         setMarkers(markersTemp);
//     }
//
//     useEffect(() => {
//         if (coords) {
//             map.setView([coords.latitude, coords.longitude]);
//             if (prevPos[1] !== currentPos[1] && prevPos[0] !== currentPos[1]) {
//                 setPrevPos(currentPos)
//             }
//             setCurrentPos([coords.latitude, coords.longitude]);
//         }
//
//     }, [coords])
//
//     return (
//         <>
//             {currentPos &&
//                 <LeafletTrackingMarker icon={icon} position={currentPos} previousPosition={prevPos}
//                                        duration={1000}/>
//             }
//         </>
//     )
//
// }

// const Direction = ({coords}) => {
//     const map = useMap();
//
//     useEffect(() => {
//         if (!map || !coords) return;
//
//         let leafletElement = L.Routing.control({
//             waypoints: [
//                 L.latLng(coords.latitude, coords.longitude),
//                 L.latLng(63.40998749977151, 10.40987139962841)
//             ],
//             // router: new L.Routing.Google(),
//             lineOptions: {
//                 styles: [
//                     {
//                         color: "blue",
//                         opacity: 0.6,
//                         weight: 4
//                     }
//                 ]
//             },
//             addWaypoints: false,
//             draggableWaypoints: false,
//             fitSelectedRoutes: false,
//             showAlternatives: false
//         }).addTo(map);
//         return leafletElement.getPlan();
//
//     }, [map, coords]);
//     return null;
// }

const MapView = () => {

    const [name, setName] = useState("Seb");
    const [positions, setPositions] = useState([]);

    if (!socket) {
        let url = "wss://follow.seb-noirot.fr"
        if (process.env.REACT_APP_WS_URL) {
            url = process.env.REACT_APP_WS_URL;
        }
        socket = new WebSocket(`${url}/position/${uuid}`);
        socket.onopen = function () {
            startInterval();
        };

        socket.onmessage = function (m) {
            let mess = JSON.parse(m.data);
            if (mess.messagePayload) {
                let payload = mess.messagePayload;
                if ("POSITION" === payload.type) {
                    let pos = positions.filter(p => p.messageHeader.name !== mess.messageHeader.name);
                    pos.push(mess);
                    setPositions(pos);
                }
            }
        };

        socket.onclose = function (close) {
            clearInterval(interval);
        }
    }

    const startInterval = () => {
        if (!interval) {
            interval = setInterval(sendPosition, 10000);
        }
    }

    const {coords, isGeolocationAvailable, isGeolocationEnabled, getPosition} =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
            onSuccess: (position) => {
                notifyNewPosition(position);
            }
        });

    const notifyNewPosition = (position) => {
        if (position) {
            let message = {
                messageHeader: {
                    name: getName(),
                    timestamp: Date.now()
                },
                messagePayload: {
                    type: "POSITION",
                    position: {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude
                    }
                }
            }
            if (socket) {
                socket.send(
                    JSON.stringify(message)
                )
            }
        }
    }

    const getName = () => {
        console.log(name);
        return name;
    }

    const sendPosition = () => {
        getPosition();
    }

    useEffect(() => {
        if (interval) {
            clearInterval(interval);
            interval = null;
            startInterval();
        }
    }, [name])

    return (
        <>
            <UncontrolledDropdown style={{position: "absolute", top: 5, right: 5, zIndex: 10000}}>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                    <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <div className="sidenav-toggler-inner">
                        <i className="sidenav-toggler-line"/>
                        <i className="sidenav-toggler-line"/>
                        <i className="sidenav-toggler-line"/>
                    </div>
                    </span>
                    </Media>

                </DropdownToggle>
                <DropdownMenu right>
                    <div className={"m-2"}>
                        <FormGroup>
                            <label
                                className="form-control-label"
                                htmlFor="notification"
                            >
                                Name
                            </label>
                            <Input
                                id="notification"
                                placeholder="Notification"
                                type="text"
                                defaultValue={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                            />
                        </FormGroup>
                    </div>
                </DropdownMenu>
            </UncontrolledDropdown>
            <MapContainer center={[51.505, -0.09]} zoom={13} scrollWheelZoom={false} style={{height: '100%'}}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <CenterOnMyPosition coords={coords}/>
                {positions.map((pos, index) => {
                    return <DisplayPosition key={pos.messageHeader.name} message={pos}/>
                })}
                {/*<Direction coords={coords}/>*/}
            </MapContainer>
        </>
    )
}
export default MapView;