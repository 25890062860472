import MapView from "../component/MapView";
import {useEffect} from "react";

const FollowTheLeader = () => {

    useEffect(() => {}, [])
    return (
        <div style={{height: '100%'}}>
            <MapView />
        </div>
    )
}

export default FollowTheLeader;